<template>
  <div>
    <a-collapse v-model:activeKey="activeKey" ghost>
      <a-collapse-panel key="1" header="This is panel header 1">
        <p>{{ text }}</p>
      </a-collapse-panel>
      <a-collapse-panel key="2" header="This is panel header 2">
        <p>{{ text }}</p>
      </a-collapse-panel>
      <a-collapse-panel key="3" header="This is panel header 3">
        <p>{{ text }}</p>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
export default defineComponent({
  setup() {
    const text = `AAAAAAAAAAA`;
    const activeKey = ref(["1"]);
    watch(activeKey, (val) => {
      console.log(val);
    });
    return { text, activeKey };
  },
});
</script>